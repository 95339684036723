import {backendApi} from "../../backend-api-sr"

export const hrcByOriginAnalysis = (() =>{
    const fetchIndex = ( async (subPath, reqBody) => {
        var url = '/api/v3/sm/production/hrc-analysis'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const fetchByOrigin = ( async (subPath, reqBody) => {
        var url = '/api/v3/sm/production/hrc-analysis/by-origin'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const fetchThick = ( async (subPath, reqBody) => {
        var url = '/api/v3/sm/production/hrc-analysis/filter-thick'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const fetchWidth = ( async (subPath, reqBody) => {
        var url = '/api/v3/sm/production/hrc-analysis/filter-width'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const fetchOrigin = ( async (subPath, reqBody) => {
        var url = '/api/v3/sm/production/hrc-analysis/filter-origin'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {fetchIndex, fetchByOrigin, fetchThick, fetchWidth, fetchOrigin};
})()