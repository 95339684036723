<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Origin </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    v-model="origin"
                                    :items="origins"
                                    default="" item-value="origin" item-text="origin"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Thick </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    v-model="thick"
                                    :items="thicks"
                                    default="" item-value="thick" item-text="thick"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                    clearable
                                    @change="(event) => getWidth(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Width </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    v-model="width"
                                    :items="widths"
                                    default="" item-value="width" item-text="width"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                    clearable
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Period From </span>
                                <v-menu ref="startDateModal" v-model="startDateModal" transition="scale-transition"
                                    offset-y min-width="auto" max-width="290px" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="ma-0 mt-2 border-12" v-model="startDate" dense clearable outlined
                                            persistent-hint append-icon="mdi-calendar"
                                            v-bind="attrs" v-on="on" hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startDate"
                                        type="month"
                                        no-title @input="startDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Period To </span>
                                <v-menu ref="endDateModal"
                                v-model="endDateModal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        outlined
                                        v-model="endDate"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 mt-2 border-12"
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="endDate"
                                        type="month"
                                        no-title @input="endDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="mt-5 border-12" style="padding: 19px;" color="info" elevation="2" small @click="submit()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-2">
            <v-col cols="12">
                <v-card outlined class="pa-md-5">  
                    <h6 class="cyan-text text-darken-3 m-0">Production HRC By Vendor</h6>
                    <div id="container1" style="height: 640px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-2">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="data"
                                    :loading="$store.state.overlay"
                                    item-class="tr_datatable"
                                    hide-default-footer
                                    fixed-header
                                    height="400"
                                    :divider="true"
                                    :light="true"
                                    :search="searchItem"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-100">
                                                <!-- <p class="cyan-text text-darken-3 m-0 hidden-mobile" style="font-size: large;">List Maintenance</p> -->
                                                <v-spacer></v-spacer>
                                                <v-text-field outlined style="max-width: 300px;" class="border-12 mr-3 mt-2" v-model="searchItem" append-icon="mdi-magnify" dense  hide-details></v-text-field>
                                                <!-- <v-btn class="border-12" style="padding: 19px;" small color="success" elevation="2" @click="openDialog('new', [])"><v-icon>mdi-plus</v-icon> New PMT </v-btn> -->
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <!-- <template v-slot:[`item.actions`]="{ item }">
                                        <td>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small class="mr-2" @click="getHeader('update', item)" v-on="on">
                                                        mdi-pencil
                                                    </v-icon>
                                                </template>
                                                <span>Edit Config</span>
                                            </v-tooltip>
                                        </td>
                                    </template> -->
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-5 mb-12">
            <v-col cols="12" xs="12" 
                sm="6" 
                md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                            class="text-center mx-auto"
                            style="margin-top: 80px;"
                            lazy-src="@/assets/empty_data.png"
                            max-width="200"
                            src="@/assets/empty_data.png"
                            v-if="showDataDetail"
                        ></v-img>
                        <div id="container2" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { isThisWeek } from "date-fns";
import { hrcByOriginAnalysis } from "../../../backend-api/sr/production/hrc_by_origin_analysis";

export default {
    data(){
        return{
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Mill',
                    disabled: false,
                    href: '/admin/sm',
                },
                {
                    text: 'Production',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'HRC By Vendor',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            origin:'',
            origins:[],
            thick:'',
            thicks:[],
            width:'',
            widths:[],
            startDateModal:false,
            startDate:'',
            endDateModal:false,
            endDate:'',
            searchItem: '',
            header:[],
            headersColumn:{},
            data:[],
            showDataDetail:true,
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getOrigin()
        await this.getThick()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        async getOrigin(){
            let reqBody = {}
            const respData = await hrcByOriginAnalysis.fetchOrigin('', reqBody)
            if (respData.status === 200) {
                this.origins = respData.data.data
            }
        },
        async getThick(){
            let reqBody = {}
            const respData = await hrcByOriginAnalysis.fetchThick('', reqBody)
            if (respData.status === 200) {
                this.thicks = respData.data.data
            }
        },
        async getWidth(event){
            let reqBody = {
                'thick' : this.thick
            }
            const respData = await hrcByOriginAnalysis.fetchWidth('', reqBody)
            if (respData.status === 200) {
                this.widths = respData.data.data
            }
        },
        async submit(){
            this.$store.dispatch('setOverlay', true)
            let startPeriode = this.startDate.replace("-", "")
            let endPeriode = this.endDate.replace("-", "")
            let reqBody = {
                'origin' : this.origin,
                'thick' : this.thick,
                'width' : this.width,
                'start_date' : startPeriode,
                'end_date' : endPeriode
            }
            const respData = await hrcByOriginAnalysis.fetchIndex('', reqBody)
            if (respData.status === 200) {
                let datas = respData.data.data
                // Chart Creation
                const transformedData = this.splitData(datas);
                const dataChart = this.createChartData(transformedData);
                this.$store.dispatch('setOverlay', false)
                this.getChart(dataChart, "container1")
                // Tabel Creation
                this.data = this.createTabel(datas)
                console.log(this.data)
                this.header = this.createHeaderTable(this.data, 'Origin')
                console.log(this.header)
                // this.headersColumn = this.createHeaderExcel(this.data, 'Origin')
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Failed to retrieve data',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
            }
        },

        splitData(data) {
            let transformedData = {};

            data.forEach(entry => {
                const { y, label, x } = entry;
                if (!(label in transformedData)) {
                    transformedData[label] = [];
                }
                transformedData[label].push({ label: x, y: parseFloat(y) });
            });

            const allXValues = data.map(entry => entry.x);
            const uniqueXValues = [...new Set(allXValues)];

            for (const label in transformedData) {
                uniqueXValues.forEach(x => {
                    const entry = transformedData[label].find(e => e.label === x);
                    if (!entry) {
                        transformedData[label].push({ label: x, y: 0 });
                    }
                });
                transformedData[label] = transformedData[label].sort((a, b) => a.label.localeCompare(b.label));
            }

            return transformedData;
        },

        createChartData(data){
            var result =[];
            for (let key in data) {
                result.push({
                    type: 'column',
                    indexLabel: "{y}",
                    indexLabelFontSize: 12,
                    // indexLabelFontColor: "#FFF",
                    name: key,
                    showInLegend: true,
                    visible: true,
                    indexLabelPlacement: "inside",
                    indexLabelOrientation: "vertical",
                    yValueFormatString: "#,###.##",
                    dataPoints: data[key].map(point => ({
                        label: point.label,
                        y: point.y,
                        click: () => this.onClickChart(point.label)
                    }))
                })
            }
            return result;
        },

        async onClickChart(event){
            this.$store.dispatch('setOverlay', true)
            let periode = event
            let reqBody = {
                'origin' : this.origin,
                'thick' : this.thick,
                'width' : this.width,
                'period' : periode
            }
            const respData = await hrcByOriginAnalysis.fetchByOrigin('', reqBody)
            // tinggal get pie chart
            if (respData.status === 200) {
                const datas = respData.data.data
                let parsedData = []
                for (var i = 0; i < datas.length; i++) {
                    parsedData.push({  label: datas[i].label,  y: parseFloat(datas[i].y), legendText: datas[i].label });
                }
                var label_detail = "Periode: "+ event;
                this.showDataDetail = false
                var container_detail = 'container2';
                var title_detail = 'HRC by Vendor'
                this.$store.dispatch('setOverlay', false)
                await this.getPieChart(parsedData, container_detail, title_detail, label_detail)
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Failed to retrieve data',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
            }
        },

        createTabel(localData){
            let transformedData = {};
            localData.forEach(entry => {
                if (!transformedData[entry.label]) {
                    transformedData[entry.label] = {};
                }
                transformedData[entry.label][entry.x] = this.$store.getters.convertToCurrencyUs(entry.y);
            });

            let result = [];
            for (const label in transformedData) {
                const obj = { "label": label, ...transformedData[label] };
                result.push(obj);
            }
            return result
        },
        createHeaderTable(localData, mainLabel){
            let yValues = [];
            localData.forEach(entry => {
                for (const key in entry) {
                    if (key !== 'label' && !yValues.includes(key)) {
                        yValues.push(key);
                    }
                }
            });
            let header = [
                { text: mainLabel, value: 'label', align: 'start' }
            ];

            yValues.forEach(yValue => {
                header.push({ text: yValue, value: yValue });
            });

            return header
        },
        createHeaderExcel(localData, mainLabel){
            let yValues = [];
            localData.forEach(entry => {
                for (const key in entry) {
                    if (key !== 'label' && !yValues.includes(key)) {
                        yValues.push(key);
                    }
                }
            });
            let header = [
                { [mainLabel] : 'label',  }
            ];

            yValues.forEach(yValue => {
                header.push({ [yValue] : yValue });
            });

            console.log(header);
            return header
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        getChart(datas, container){
            var self = this;
            console.log(datas)

            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    title: "Ton",
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true,
                    yValueFormatString: "##0.00",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    horizontalAlign: "center",
                    verticalAlign: "bottom",
                },
                data: datas
            });
            chart.render();
        },
        getPieChart(dt_detail, container_detail, title_detail, label_detail, destroy = false){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent% {y}",
                    indexLabelFontSize: 12,
                    legendText: "{label}",
                    showInLegend: true

                }]
            });

            pie_chart.options.data[0].dataPoints = dt_detail;
            // this.showDefaultText(pie_chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (x.matches) {

                for(var i = 0; i < pie_chart.options.data.length; i++){
                    pie_chart.options.data[i].indexLabelFontSize = 6;
                }
                pie_chart.render();
            }
            
            pie_chart.render();

            if (destroy == true) {
                pie_chart.destroy();
            }
        }

    },
}
</script>
<style scoped>

</style>